* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'poppins', sans-serif;
    scroll-behavior: smooth;
    color: var(--text-color);
}

body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
}

.container {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
    overflow: hidden;
}

h1 {
    margin-top: 0;
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
}

.difficulty {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    position: relative;
    z-index: 2;
}

.difficulty label {
    margin-right: 15px;
    cursor: pointer;
    transition: transform 0.3s;
    display: inline-block;
    position: relative;
}

.difficulty label:hover {
    transform: translateY(-5px);
}

.difficulty input[type="radio"] {
    display: none;
}

.difficulty input[type="radio"] + img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid transparent;
    transition: border-color 0.3s;
    filter: grayscale(100%);
}

.difficulty input[type="radio"]:checked + img {
    border-color: #4caf50;
    filter: grayscale(0%);
}

#selected-difficulty {
    font-weight: bold;
    margin-top: 20px;
    position: relative;
    z-index: 2;
}

/* Effet de fond */
.background-shapes {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.shape {
    position: absolute;
    width: 300px;
    height: 300px;
    background: linear-gradient(135deg, #4caf50, #00bcd4);
    border-radius: 50%;
    opacity: 0.2;
}

.shape1 {
    top: -100px;
    left: -100px;
    animation: move1 20s linear infinite alternate;
}

.shape2 {
    top: 200px;
    right: -50px;
    animation: move2 15s linear infinite alternate;
}

.shape3 {
    bottom: -50px;
    left: 200px;
    animation: move3 18s linear infinite alternate;
}

@keyframes move1 {
    0% {
        transform: translate(-100px, -100px);
    }
    100% {
        transform: translate(200px, 200px);
    }
}

@keyframes move2 {
    0% {
        transform: translate(0, 200px);
    }
    100% {
        transform: translate(300px, -100px);
    }
}

@keyframes move3 {
    0% {
        transform: translate(200px, -50px);
    }
    100% {
        transform: translate(-100px, 250px);
    }
}

.play-button {
    padding: 15px 30px;
    font-size: 1.2rem;
    font-weight: bold;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.play-button:hover {
    background-color: #0056b3;
}

.difficulty-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.difficulty-container img {
    margin: 0 20px;
    border-radius: 15px;
}
